import React from "react";
import moment from "moment";
import produce from "immer";
import groupBy from "lodash/groupBy";
import { Typography } from "@mui/material";
import * as calendarFns from "../../../components/Calendar/functions/calendarFunctions";
import { LabObject } from "../../../interfaces/LabInterfaces";
import {
  DefaultRange,
  LabTest,
  LabTestRecordResultData,
  MaleFemaleChildRange,
  SpecialRange,
  TestTypes
} from "../../../interfaces/Lab";
import hasOwnProperty from "../../../helpers/object";
import { AuthorizedSp } from "./LabprintComponents/AuthorizedSPInfo";
import { removeCommasFromNumber } from "../../../helpers/number";

const DEFAULT_FONT_SCALE = 1.15;

export const scaleFont = (size: string, scale: number = DEFAULT_FONT_SCALE): string => {
  const fontSize = size.replace(/[^0-9.]/g, "");
  const suffix = size.replace(fontSize, "");
  return Number(fontSize) * scale + suffix;
};

export const rangeSwitchAccToGender = (
  gender: number,
  range: Record<string, any>
): null | string => {
  // function to get range based on gender
  if (range) {
    if (Number(gender) === 1 && hasOwnProperty(range, "male")) {
      if (hasOwnProperty(range.male, "min") && hasOwnProperty(range.male, "max")) {
        return `${range.male.min} - ${range.male.max}`;
      }

      if (hasOwnProperty(range.male, "min")) return range.male.min as string;
      if (hasOwnProperty(range.male, "max")) return range.male.max as string;
      return null;
    }
    if (Number(gender) === 2 && hasOwnProperty(range, "female")) {
      if (hasOwnProperty(range.female, "min") && hasOwnProperty(range.female, "max")) {
        return `${range.female.min} - ${range.female.max}`;
      }

      if (hasOwnProperty(range.female, "min")) return range.female.min as string;
      if (hasOwnProperty(range.female, "max")) return range.female.max as string;
      return null;
    }

    if (hasOwnProperty(range, "default")) {
      if (hasOwnProperty(range.default, "min") && hasOwnProperty(range.default, "max")) {
        return `${range.default.min} - ${range.default.max}`;
      }
      if (hasOwnProperty(range.default, "min")) return range.default.min as string;
      if (hasOwnProperty(range.default, "max")) return range.default.max as string;
      return null;
    }
  }

  return null;
};

export type AdvancedRange = MaleFemaleChildRange | DefaultRange | SpecialRange;

export function advancedLabRangeProcessor(
  range: AdvancedRange,
  gender?: number,
  dob?: string
): string | JSX.Element {
  if (hasOwnProperty(range, "default")) {
    return `${range.default.min} - ${range.default.max}`;
  }
  if (dob && moment().diff(dob, "years") < 13 && hasOwnProperty(range, "children")) {
    return `${range.children.min} - ${range.children.max}`;
  }
  if (gender) {
    if (Number(gender) === 1 && hasOwnProperty(range, "male")) {
      return `${range.male.min} - ${range.male.max}`;
    }
    if (Number(gender) === 2 && hasOwnProperty(range, "female")) {
      return `${range.female.min} - ${range.female.max}`;
    }
  }
  if (hasOwnProperty(range, "other")) {
    const htmlValue = range.other.label || "";
    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{ __html: htmlValue }} />;
  }
  if (hasOwnProperty(range, "male") || hasOwnProperty(range, "female")) {
    return `${range.male.min || range.female.min} - ${range.male.max || range.female.max}`;
  }
  return ``;
}

const isNumberLike = (value: unknown): boolean =>
  value !== "" &&
  value !== null &&
  value !== undefined &&
  !Number.isNaN(removeCommasFromNumber(value));

const getHighAndLowSymbol = (min, max, reading) => ({
  highSymbol: Number(removeCommasFromNumber(max)) < reading ? "High" : null,
  lowSymbol: Number(removeCommasFromNumber(min)) > reading ? "Low" : null
});

export function advancedLabRangeIndicator(
  reading: number,
  ranges: AdvancedRange,
  dob: string,
  gender: number | string
): string | { symbol: string; highSymbol: string | null; lowSymbol: string | null } {
  const symbol = "*";
  if (hasOwnProperty(ranges, "default")) {
    if (!isNumberLike(ranges.default.min) || !isNumberLike(ranges.default.max)) return "";
    if (
      Number(removeCommasFromNumber(ranges.default.min)) > reading ||
      Number(removeCommasFromNumber(ranges.default.max)) < reading
    ) {
      return {
        symbol,
        ...getHighAndLowSymbol(ranges.default.min, ranges.default.max, reading)
      };
    }
    return "";
  }
  if (dob && hasOwnProperty(ranges, "children") && moment().diff(dob, "years") < 13) {
    if (!isNumberLike(ranges.children.min) || !isNumberLike(ranges.children.max)) {
      return "";
    }
    if (
      Number(removeCommasFromNumber(ranges.children.min)) > reading ||
      Number(removeCommasFromNumber(ranges.children.max)) < reading
    ) {
      return {
        symbol,
        ...getHighAndLowSymbol(ranges.children.min, ranges.children.max, reading)
      };
    }
    return "";
  }
  if (hasOwnProperty(ranges, "male") && Number(gender) === 1) {
    if (!isNumberLike(ranges.male.min) || !isNumberLike(ranges.male.max)) return "";
    if (
      Number(removeCommasFromNumber(ranges.male.min)) > reading ||
      Number(removeCommasFromNumber(ranges.male.max)) < reading
    ) {
      return {
        symbol,
        ...getHighAndLowSymbol(ranges.male.min, ranges.male.max, reading)
      };
    }
    return "";
  }
  if (hasOwnProperty(ranges, "female") && Number(gender) === 2) {
    if (!isNumberLike(ranges.female.min) || !isNumberLike(ranges.female.max)) return "";
    if (
      Number(removeCommasFromNumber(ranges.female.min)) > reading ||
      Number(removeCommasFromNumber(ranges.female.max)) < reading
    ) {
      return {
        symbol,
        ...getHighAndLowSymbol(ranges.male.min, ranges.female.max, reading)
      };
    }
    return "";
  }
  if (hasOwnProperty(ranges, "male") || hasOwnProperty(ranges, "female")) {
    const maleMin = hasOwnProperty(ranges, "male") ? ranges.male.min : null;
    const maleMax = hasOwnProperty(ranges, "male") ? ranges.male.max : null;
    const femaleMin = hasOwnProperty(ranges, "female")
      ? (ranges.female as MaleFemaleChildRange["female"]).min
      : null;
    const femaleMax = hasOwnProperty(ranges, "female")
      ? (ranges.female as MaleFemaleChildRange["female"]).max
      : null;
    if (hasOwnProperty(ranges, "male")) {
      if (!isNumberLike(maleMin) || !isNumberLike(maleMax)) return "";
    }
    if (hasOwnProperty(ranges, "female")) {
      if (!isNumberLike(femaleMin) || !isNumberLike(femaleMax)) return "";
    }
    if (Number(maleMin || femaleMin) > reading || Number(maleMax || femaleMax) < reading) {
      return {
        symbol,
        ...getHighAndLowSymbol(maleMin || femaleMin, maleMax || femaleMax, reading)
      };
    }
  }
  if (hasOwnProperty(ranges, "other")) {
    if (!isNumberLike(ranges.other.min) || !isNumberLike(ranges.other.max)) return "";
    if (
      Number(removeCommasFromNumber(ranges.other.min)) > reading ||
      Number(removeCommasFromNumber(ranges.other.max)) < reading
    ) {
      return {
        symbol,
        ...getHighAndLowSymbol(ranges.other.min, ranges.other.max, reading)
      };
    }
  }
  return "";
}

export function AdvancedLabRangeHandler({
  range,
  dob,
  gender
}: {
  range: MaleFemaleChildRange | DefaultRange | SpecialRange;
  dob: string;
  gender: number;
}): JSX.Element {
  const processedRange = advancedLabRangeProcessor(range, gender, dob);
  return <Typography style={{ whiteSpace: "pre-wrap" }}>{processedRange}</Typography>;
}

export const rangeIndicator = (
  range: Record<string, any>,
  val: number | string,
  symbol = "*",
  min = null,
  max = null
): string => {
  if (min || max) {
    if (!val || Number.isNaN(val)) return "";
    // case for range with "other"
    if (min && max) {
      if (Number(val) < min && Number(val) > max) {
        return symbol;
      }
      return "";
    }
    if (min) {
      if (Number(val) < min) {
        return symbol;
      }
      return "";
    }
    if (max) {
      if (Number(val) > max) {
        return symbol;
      }
      return "";
    }
  }

  // function to get range indicator based on range and reading
  if (!range) return "";
  const rangeArr = range.split("-");
  if (
    !rangeArr ||
    !rangeArr[0] ||
    !rangeArr[1] ||
    rangeArr.length !== 2 ||
    !val ||
    Number.isNaN(val)
  ) {
    return "";
  }
  if (Number(val) < Number(rangeArr[0]) || Number(val) > Number(rangeArr[1])) {
    return symbol;
  }
  return "";
};

export const verifyShowTestCategory = (test: LabTestRecordResultData): boolean => {
  // function to determine if testCategory should be shown or not
  const hasSameNameAsFirstChildTest =
    test.labTests?.length > 0 && test.labTests[0].name === test.name;
  const shouldHide = hasSameNameAsFirstChildTest;
  return !shouldHide;
};

export const getSettingBasedDate = (dateSetting = "BS", date: string, showTime = false): string => {
  // function to get date based on setting
  const time = showTime ? moment(date).format("LT") : "";
  if (dateSetting === "AD") return `${moment(date).format("LL")} ${time}`;
  return `${calendarFns.bsFullDate(date)} ${time}`;
};

export const applySpaciousMargins = (scale = 1): JSX.Element => {
  // function to get an empty div with margins
  const marginToApply = `${0.5 * scale}cm`;
  return React.createElement(
    "div",
    { style: { marginTop: marginToApply, marginBottom: marginToApply } },
    ""
  );
};

export const getSectionStyle = (
  headerColor: string,
  headerMarginBottom: string = "4mm"
): Record<string, any> => ({
  headerStyle: {
    backgroundColor: headerColor || "none",
    color: headerColor ? "white" : "black",
    paddingTop: "10mm",
    paddingRight: "10mm",
    paddingLeft: "10mm",
    paddingBottom: headerColor ? "10mm" : "6mm",
    marginBottom: headerMarginBottom
  },
  bodyStyle: {
    paddingLeft: "10mm",
    paddingRight: "10mm"
  },
  footerStyle: {
    borderTop: "1px solid grey",
    paddingLeft: "10mm",
    paddingRight: "10mm",
    paddingBottom: "10mm"
  },
  rawHeaderStyle: {
    backgroundColor: headerColor || "none",
    color: headerColor ? "white" : "black",
    border: "1px solid black"
  }
});

export const groupLabTestByCategory = (labData: LabObject): LabObject =>
  produce(labData, (draft) => {
    // eslint-disable-next-line no-param-reassign
    draft.results.data = groupBy(draft.results.data, "category");
  });

const defaultspecifiedCategoryOrder = [
  "biochemistry",
  "haematology",
  "serology",
  "urine",
  "stool",
  "genetics",
  "toxicology",
  "special tests"
];

export const sortCategoryKeys = (
  keys: Array<string>,
  specifiedCategoryOrder: Array<string> = defaultspecifiedCategoryOrder
): Array<string> => {
  const sorted = keys.sort((a, b) => {
    let sortValueA = specifiedCategoryOrder.indexOf(a.toLowerCase());
    let sortValueB = specifiedCategoryOrder.indexOf(b.toLowerCase());
    if (sortValueA === -1) sortValueA = undefined;
    if (sortValueB === -1) sortValueB = undefined;

    if (sortValueA === sortValueB) return 0;
    if (sortValueA === undefined) return 1;
    if (sortValueB === undefined) return -1;

    return sortValueA > sortValueB ? 1 : -1;
  });
  return sorted;
};

export const getGender = (lt: LabTest, gender: "1" | "2"): "1" | "2" | null => {
  if (lt.ranges) {
    if (hasOwnProperty(lt.ranges, "default") && lt.ranges.default) return null;
    if (
      (hasOwnProperty(lt.ranges, "male") && lt.ranges.male) ||
      (hasOwnProperty(lt.ranges, "female") && lt.ranges.female)
    ) {
      if (gender === "1" || gender === "2") return gender;
      return "1";
    }
    return null;
  }
  return null;
};

export const createMarkup = <T,>(message: T): { __html: T } => ({
  __html: message
});

export const showInterpretationTemplate = (template: {
  nodes: Array<Array<{ x: number; y: number; data: string }>>;
  title: string;
}): boolean => {
  if (
    template.nodes &&
    Array.isArray(template.nodes) &&
    template.nodes.length > 0 &&
    template.nodes[0].length > 0
  ) {
    return true;
  }
  return false;
};

export const titleCase = (str: string): string => {
  const referrArr = str.toLowerCase().split(" ");
  const result = referrArr.map((val) => val.replace(val.charAt(0), val.charAt(0).toUpperCase()));
  return result.join(" ");
};

export const showDynamicSignature = (
  currentServiceProvider: AuthorizedSp,
  ...otherServiceProviders: Array<AuthorizedSp>
): boolean => {
  const otherServiceProvidersId = otherServiceProviders.map(
    (serviceProvider) => serviceProvider?.id
  );
  return currentServiceProvider && !otherServiceProvidersId.includes(currentServiceProvider?.id);
};

export const showCategory = (labTestsData: Array<LabTestRecordResultData>): boolean =>
  labTestsData.some(
    (labTestData) =>
      labTestData.type === TestTypes.RADIOLOGY ||
      labTestData.labTests?.some(
        (labTest) =>
          labTest.formData?.reading || labTest.subTests?.some((subTest) => subTest.formData.reading)
      )
  );

export const showSubRow = (hasSubTests: boolean, labTest: LabTest): boolean =>
  hasSubTests
    ? Boolean(labTest.formData.reading) ||
      labTest.subTests?.some((subTest) => subTest.formData?.reading)
    : Boolean(labTest.formData.reading);

export const enum LabHeadersFlexBasis {
  "Result" = "5.5cm",
  "Unit" = "2.5cm",
  "Range" = "3cm",
  "Methods" = "3cm",
  "Flag" = "1.5cm"
}

export const enum LabHeadersFlexBasisLargeFont {
  "Result" = "4.5cm",
  "Unit" = "2.5cm",
  "Flag" = "1.5cm",
  "Range" = "3cm",
  "Methods" = "3cm"
}

export const addDefaultFormData = (test: LabTest): LabTest => {
  if (!hasOwnProperty(test, "formData")) {
    const modifiedLT = { ...test, formData: { reading: "", flag: "normal" } };
    return modifiedLT;
  }
  return test;
};

export const readingPresent = (labData: Record<string, any>): boolean => {
  const { data } = labData.results;
  const readingDataArray = Object.values(data).map((items: any) =>
    items?.map(
      (item) =>
        item.labTests &&
        item.labTests.map((labTest) => {
          const { formData, subTests } = labTest;
          const subTestsLength = subTests?.filter((subTest) =>
            Boolean(subTest.formData?.reading)
          ).length;
          return (formData?.reading ? 1 : 0) + (subTests ? subTestsLength : 0);
        })
    )
  );
  return Boolean(readingDataArray.flat(2).reduce((prevSum, a) => prevSum + a, 0));
};

export const isRadiologyOrCytology = (type: string): boolean =>
  ["radiology", "cytology"].includes(type);
