import * as React from "react";
import { Box, FormGroup, FormControlLabel, Checkbox, Typography, TextField } from "@mui/material";
import { chain, round } from "mathjs";
import { tl, t } from "../../../components/translate";
import NrsFormat from "../../../components/FormattingInput/NrsInput";
import styles from "./BillEditor.module.css";
import { paymentOptionsEnum } from "./BillSummary";
import hasOwnProperty from "../../../helpers/object";
import { BillDocumentType } from "../../../interfaces/BillInterfaces";

/**
 * @todo refactor payment info for invoice bill and credit note (into two separate components) when there is time
 * @todo complexities are coming up ahead because of handling advance, too many if else cases are being handled
 */

interface PaymentInfo {
  draft: BillDocumentType;
  updateDraft: (v) => void;
  isCreditNote: boolean;
}

const PaymentInfo = ({ draft, updateDraft, isCreditNote }: PaymentInfo): JSX.Element => {
  const paymentInfo = { ...draft.paymentInfo } || {
    paymentMethod: paymentOptionsEnum.cash,
    paid: true,
    paidAmount: draft.summary.totalAmount,
    paymentDistribution: {
      additionalPayment:
        draft.client.balance < draft.summary.totalAmount
          ? chain(draft.summary.totalAmount).subtract(draft.client.balance)
          : 0,
      paymentFromBalance: draft.client.balance
    }
  };

  React.useEffect(() => {
    if (paymentInfo.paid === true) {
      // when paid is true, set paidAmount = totalAmount
      if (isCreditNote) {
        if (draft.refundInfo.maximumRefundable > 0) {
          paymentInfo.paymentMethod = draft.refundInfo.previousPaymentMethod;
          paymentInfo.paidAmount = draft.refundInfo.maximumRefundable;
        } else {
          paymentInfo.paymentMethod = paymentOptionsEnum.noPayment;
          paymentInfo.paidAmount = 0;
        }
        paymentInfo.paid = draft.refundInfo.maximumRefundable > 0;
      } else {
        paymentInfo.paidAmount = draft.summary.totalAmount;
        const { balance } = draft.client;
        if (balance > 0 && draft.summary.totalAmount > balance)
          paymentInfo.paymentDistribution = {
            paymentFromBalance: balance,
            additionalPayment: chain(draft.summary.totalAmount || 0)
              .subtract(balance)
              .done()
          };
      }
      updateDraft({
        ...draft,
        paymentInfo
      });
    } else if (isCreditNote && hasOwnProperty(draft.refundInfo, "maximumRefundable")) {
      if (draft.refundInfo.maximumRefundable > 0) {
        paymentInfo.paidAmount = draft.refundInfo.maximumRefundable;
        paymentInfo.paid = true;
        paymentInfo.paymentMethod = draft.refundInfo.previousPaymentMethod;
      } else {
        paymentInfo.paidAmount = 0;
        paymentInfo.paid = false;
        paymentInfo.paymentMethod = paymentOptionsEnum.noPayment;
      }
      updateDraft({ ...draft, paymentInfo });
    }
  }, [draft.summary.totalAmount]);
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={paymentInfo.paid}
            disabled={isCreditNote}
            data-testmation="billingPaidAll"
            onChange={(e, checked) => {
              if (!draft.client.isWalkInCustomer) {
                let paidAmt;
                if (checked) {
                  if (isCreditNote) {
                    if (draft.refundInfo.maximumRefundable > 0)
                      paidAmt = draft.refundInfo.maximumRefundable;
                    else paidAmt = 0;
                  } else paidAmt = draft.summary.totalAmount;
                } else {
                  paidAmt = 0;
                }

                let paymentMethod;
                if (isCreditNote) {
                  if (paidAmt !== 0) paymentMethod = draft.refundInfo.previousPaymentMethod;
                  else paymentMethod = paymentOptionsEnum.noPayment;
                } else paymentMethod = paymentInfo.paymentMethod;

                updateDraft({
                  ...draft,
                  paymentInfo: {
                    paymentMethod,
                    paid: isCreditNote
                      ? paidAmt === draft.refundInfo.maximumRefundable
                      : paidAmt >= draft.summary.totalAmount,
                    paidAmount: paidAmt,
                    ...(!isCreditNote
                      ? {
                          paymentDistribution: {
                            paymentFromBalance: 0,
                            additionalPayment: paidAmt
                          }
                        }
                      : {})
                  }
                });
              }
            }}
          />
        }
        label={isCreditNote ? t("billing.refundedAll") : t("billing.paidAll")}
      />
      {Boolean(!paymentInfo.paid || paymentInfo.paidAmount) && (
        <>
          {draft.paymentInfo.paymentMethod !== paymentOptionsEnum.balance && (
            <Box component="div" display="flex">
              <Box
                component="span"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                width="100%"
                fontWeight={500}
              >
                {isCreditNote
                  ? tl("billing.refundAmount")
                  : tl(`billing.${draft.paymentInfo.paymentMethod}Payment`)}
              </Box>
              <Box component="span" marginLeft="16px" textAlign="left">
                <TextField
                  disabled={isCreditNote}
                  data-testmation="billingPaidAmount"
                  value={
                    isCreditNote
                      ? paymentInfo.paidAmount
                      : (
                          (hasOwnProperty(
                            Object(paymentInfo.paymentDistribution),
                            "additionalPayment"
                          )
                            ? paymentInfo.paymentDistribution.additionalPayment
                            : paymentInfo.paidAmount) || 0
                        ).toFixed(2)
                  }
                  margin="dense"
                  variant="outlined"
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                    if (paymentInfo.paymentMethod !== paymentOptionsEnum.noPayment) {
                      let paidAmount = Number(e.target.value);
                      // paidAmount cannot go over total amount or maximum refundable amount

                      if (isCreditNote) {
                        if (!(draft.refundInfo.maximumRefundable >= paidAmount)) {
                          if (draft.refundInfo.maximumRefundable > 0)
                            paidAmount = draft.refundInfo.maximumRefundable;
                          else paidAmount = 0;
                        }
                      } else if (draft.summary.totalAmount < paidAmount)
                        paidAmount = draft.summary.totalAmount;

                      const remainingPayment = chain(draft.summary.totalAmount)
                        .subtract(paidAmount)
                        .done();

                      const paymentFromBalance =
                        remainingPayment > draft.client.balance
                          ? draft.client.balance
                          : remainingPayment;

                      updateDraft({
                        ...draft,
                        paymentInfo: {
                          paymentMethod: paymentInfo.paymentMethod,
                          paid: isCreditNote
                            ? paidAmount >= draft.refundInfo.maximumRefundable
                            : chain(paidAmount).add(paymentFromBalance).done() >=
                              draft.summary.totalAmount,
                          ...(isCreditNote
                            ? { paidAmount }
                            : {
                                paidAmount: chain(paidAmount).add(paymentFromBalance).done(),
                                paymentDistribution: {
                                  paymentFromBalance,
                                  additionalPayment: paidAmount
                                }
                              })
                        }
                      });
                    }
                  }}
                  InputProps={{
                    inputComponent: NrsFormat as any,
                    startAdornment: tl("rs"),
                    classes: { input: styles.nrsInput }
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  inputProps={{ allowNegative: isCreditNote }}
                />
              </Box>
            </Box>
          )}
          {!isCreditNote && (
            <Box component="div" marginBottom="4px" display="flex">
              <Box
                component="span"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                width="100%"
                fontWeight={500}
              >
                {tl("billing.paidFromBalance")}
              </Box>
              <Box component="span" marginLeft="16px" textAlign="left">
                <TextField
                  disabled={isCreditNote || !draft.client.balance}
                  data-testmation="billingPaidFromBalance"
                  value={(
                    (paymentInfo.paymentMethod === paymentOptionsEnum.balance
                      ? paymentInfo.paidAmount
                      : paymentInfo.paymentDistribution?.paymentFromBalance) || 0
                  ).toFixed(2)}
                  onChange={(event) => {
                    // let user to change how much to pay from clients balance,
                    // this will make possible to finalise bill in credit even if clients balance exist
                    const balanceInputAmount = Number(event.target.value);
                    if (!draft.client.isWalkInCustomer) {
                      let paidAmt;
                      if (balanceInputAmount <= (draft.client.balance || 0)) {
                        if (balanceInputAmount >= draft.summary.totalAmount) {
                          paidAmt = draft.summary.totalAmount;
                        } else {
                          paidAmt = balanceInputAmount;
                        }
                      } else if (balanceInputAmount >= draft.summary.totalAmount) {
                        paidAmt = draft.summary.totalAmount;
                      } else {
                        paidAmt = draft.client.balance || 0;
                      }

                      let paymentMethod;
                      if (paidAmt === 0) {
                        paymentMethod = paymentOptionsEnum.cash;
                      } else {
                        paymentMethod = paymentInfo.paymentMethod;
                      }

                      updateDraft({
                        ...draft,
                        paymentInfo: {
                          paymentMethod,
                          paid: paidAmt >= draft.summary.totalAmount,
                          paidAmount: paidAmt,
                          ...(!isCreditNote
                            ? {
                                paymentDistribution: {
                                  paymentFromBalance: paidAmt,
                                  additionalPayment: 0
                                }
                              }
                            : {})
                        }
                      });
                    }
                  }}
                  margin="dense"
                  variant="outlined"
                  InputProps={{
                    inputComponent: NrsFormat as any,
                    startAdornment: tl("rs"),
                    classes: { input: styles.nrsInput }
                  }}
                />
              </Box>
            </Box>
          )}
          {!isCreditNote && draft.paymentInfo.paymentMethod !== paymentOptionsEnum.balance && (
            <Typography>
              <Box component="div" display="flex">
                <Box
                  component="span"
                  fontSize="0.83em"
                  display="inline-block"
                  width="100%"
                  fontWeight={500}
                >
                  {tl("billing.totalPaid")}
                </Box>
                <Box
                  component="span"
                  fontSize="0.83em"
                  marginLeft="15px"
                  fontWeight={500}
                  textAlign="right"
                >
                  <TextField
                    value={(draft.paymentInfo.paidAmount || 0).toFixed(2)}
                    className={styles.nrsReadInputRoot}
                    disabled
                    InputProps={{
                      inputComponent: NrsFormat as any,
                      startAdornment: t("rs"),
                      classes: {
                        root: styles.nrsReadInputRoot,
                        input: styles.nrsReadInput
                      }
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{ allowNegative: isCreditNote }}
                  />
                </Box>
              </Box>
            </Typography>
          )}
          {(draft?.summary?.totalAmount !== paymentInfo?.paidAmount ||
            (isCreditNote && draft.refundInfo.maximumRefundable > 0)) && (
            <Typography>
              <Box component="span" display="flex">
                <Box
                  component="span"
                  fontSize="0.83em"
                  display="inline-block"
                  width="100%"
                  fontWeight={500}
                >
                  {isCreditNote ? tl("billing.remaingRefundable") : tl("billing.dueAmount")}
                </Box>
                <Box
                  component="span"
                  fontSize="0.83em"
                  marginLeft="15px"
                  fontWeight={500}
                  textAlign="right"
                >
                  <TextField
                    value={(
                      (isCreditNote
                        ? chain(draft.refundInfo.maximumRefundable || 0)
                            .subtract(paymentInfo.paidAmount || 0)
                            .done()
                        : chain(draft?.summary?.totalAmount)
                            .subtract(paymentInfo?.paidAmount || 0)
                            .done()) || 0
                    ).toFixed(2)}
                    margin="dense"
                    className={styles.nrsReadInputRoot}
                    disabled
                    InputProps={{
                      inputComponent: NrsFormat as any,
                      startAdornment: t("rs"),
                      classes: {
                        root: styles.nrsReadInputRoot,
                        input: styles.nrsReadInput
                      }
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{ allowNegative: isCreditNote }}
                  />
                </Box>
              </Box>
            </Typography>
          )}
        </>
      )}
      {draft.paymentInfo.paymentMethod === paymentOptionsEnum.cash &&
        paymentInfo.paid &&
        !isCreditNote && (
          <Box sx={{ my: 1 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography component="label">{tl("billing.tenderAmount")}</Typography>
              <TextField
                data-testmation="tenderAmountInput"
                InputProps={{
                  inputComponent: NrsFormat,
                  startAdornment: tl("rs"),
                  classes: { input: styles.nrsInput }
                }}
                onFocus={(e) => e.target.select()}
                margin="dense"
                variant="outlined"
                value={draft.paymentInfo.tenderAmount}
                onChange={(event) => {
                  const changeAmount =
                    Number(event.target.value) > draft.summary.totalAmount
                      ? Number(event.target.value) - draft.summary.totalAmount
                      : 0;
                  updateDraft({
                    ...draft,
                    paymentInfo: {
                      ...draft.paymentInfo,
                      tenderAmount: Number(event.target.value),
                      changeAmount
                    }
                  });
                }}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography component="label">{tl("billing.changeAmount")}</Typography>
              <TextField
                disabled
                value={round(draft.paymentInfo.changeAmount || 0, 2).toFixed(2)}
                InputProps={{
                  inputComponent: NrsFormat,
                  startAdornment: tl("rs"),
                  classes: { input: styles.nrsInput }
                }}
                margin="dense"
                variant="outlined"
              />
            </Box>
          </Box>
        )}
    </FormGroup>
  );
};

export default PaymentInfo;
